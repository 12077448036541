// export const pictureData = {
//     opening: ['https://cdn.redacted.redacted/anolHaKyUuBi.JPG', 'https://cdn.redacted.redacted/qlgfU1dUbmDm.JPG', 'https://cdn.redacted.redacted/DGxLtnta99f3.JPG', 'https://cdn.redacted.redacted/3nvYnE8eOhfe.JPG', 'https://cdn.redacted.redacted/RzNsrw4IKJuc.JPG', 'https://cdn.redacted.redacted/9EQN6gds60T2.JPG', 'https://cdn.redacted.redacted/OZ2hvJnXIE7w.JPG', 'https://cdn.redacted.redacted/HwRhuOErFiwo.JPG', 'https://cdn.redacted.redacted/Wd5wWjxefBU6.JPG', 'https://cdn.redacted.redacted/T9N5mWZOX6tg.JPG', 'https://cdn.redacted.redacted/ptcf10WmB2nE.JPG'],
//     basketball: ['https://cdn.redacted.redacted/19pGXMeVQnus.JPG', 'https://cdn.redacted.redacted/Af2iFTuO61FM.JPG', 'https://cdn.redacted.redacted/u4F5EAJ3EvBm.JPG', 'https://cdn.redacted.redacted/Xe3jV1HFCXDV.JPG', 'https://cdn.redacted.redacted/oTBh98CNIFTk.JPG', 'https://cdn.redacted.redacted/cA3VjaJi5205.JPG', 'https://cdn.redacted.redacted/tsPoXFbbmVah.JPG', 'https://cdn.redacted.redacted/xkFYCRsczZoI.JPG'],
//     futsal: ['https://cdn.redacted.redacted/dYFukZto8FWA.JPG', 'https://cdn.redacted.redacted/x3Wh0ssCayIZ.JPG', 'https://cdn.redacted.redacted/L3Y2CMXbeIF3.JPG', 'https://cdn.redacted.redacted/uMWPb7jJrWRG.JPG', 'https://cdn.redacted.redacted/8hB8IVTMGuIs.JPG', 'https://cdn.redacted.redacted/FxP96bGlvrGn.JPG', 'https://cdn.redacted.redacted/xn0CkBZiQNay.JPG', 'https://cdn.redacted.redacted/KGPSeOGOunix.JPG'],
//     modernDance: ['https://cdn.redacted.redacted/uZFsxBZ724sU.JPG', 'https://cdn.redacted.redacted/yBntBo9NsdAY.JPG', 'https://cdn.redacted.redacted/bfPjXD8orEOl.JPG', 'https://cdn.redacted.redacted/vWLRZ6TvapqH.JPG', 'https://cdn.redacted.redacted/SwCwS6UG4jAW.JPG', 'https://cdn.redacted.redacted/jnAmfzeRYKIk.JPG', 'https://cdn.redacted.redacted/eaSxGPA93dWV.JPG', 'https://cdn.redacted.redacted/v9KflZJwTmrj.JPG', 'https://cdn.redacted.redacted/a4wOB9MF3wLc.JPG', 'https://cdn.redacted.redacted/zVZ95QoDXdOI.JPG'],
//     soloVocal: ['https://cdn.redacted.redacted/30LzdkrLjPRA.png', 'https://cdn.redacted.redacted/LpaStUsO8BrZ.png', 'https://cdn.redacted.redacted/H21nGNEU8Zop.png', 'https://cdn.redacted.redacted/7m9SefzsmEF3.png', 'https://cdn.redacted.redacted/BvnRamZIPcoa.png'],
//     panitia: [baseCdnUrl + "/Jy2xvMc4yZi7.JPG", baseCdnUrl + "/zK3g2JYdOt9G.JPG", baseCdnUrl + "/2j41Mw3NdsX8.JPG", baseCdnUrl + "/PIr97OuasbIx.JPG", baseCdnUrl + "/aUIv9aaVDeeP.JPG", baseCdnUrl + "/xOnoiblJ5WVO.JPG"],
//     closing: ['https://cdn.redacted.redacted/CEKhIBqWFlsI.JPG', 'https://cdn.redacted.redacted/DDUdv98Ka7XK.JPG', 'https://cdn.redacted.redacted/syOo6TxhIOZq.JPG', 'https://cdn.redacted.redacted/17GzdA7HsWGm.JPG', 'https://cdn.redacted.redacted/sHqgAcbykO5R.JPG', 'https://cdn.redacted.redacted/Yptl28z5u7Vq.JPG']
// }

import {baseCdnUrl} from "../AppReduced";

export const pictureData = {
    "opening": [baseCdnUrl + "/Xel0SFTUtBBj.webp", baseCdnUrl + "/WzweNPEbfAkx.webp", baseCdnUrl + "/JJe08oKvymz2.webp", baseCdnUrl + "/zs72uIjEQbSK.webp", baseCdnUrl + "/fQc8ozdC406t.webp", baseCdnUrl + "/uQjOK5e6NKx8.webp", baseCdnUrl + "/nF5ynrBq9SKc.webp", baseCdnUrl + "/ZL1nJas0x7yo.webp", baseCdnUrl + "/4a0ZZFJCvWy2.webp", baseCdnUrl + "/FEfoH3b5OV9e.webp", baseCdnUrl + "/9NYQx6ochtra.webp"],
    "basketball": [baseCdnUrl + "/myfX8cq7CUMh.webp", baseCdnUrl + "/mRxtbZeEXEMN.webp", baseCdnUrl + "/8QD5IYNffIDH.webp", baseCdnUrl + "/YKUhF8cLCieO.webp", baseCdnUrl + "/IsSM6bcZxuw0.webp", baseCdnUrl + "/ipPWuKCZOGxf.webp", baseCdnUrl + "/6fRpLF69a8sZ.webp", baseCdnUrl + "/0ZV5kBQQ4SKO.webp"],
    "futsal": [baseCdnUrl + "/F4BjA6IvStWZ.webp", baseCdnUrl + "/XBist6gv9SIz.webp", baseCdnUrl + "/GexMFtno4ykw.webp", baseCdnUrl + "/BSTzmZTMeHiV.webp", baseCdnUrl + "/jTa1bJwNY3sq.webp", baseCdnUrl + "/vb6V8safXpkU.webp", baseCdnUrl + "/ZoosxfVBLLzt.webp", baseCdnUrl + "/l6hzGePE0Z95.webp"],
    "modernDance": [baseCdnUrl + "/kxbGMz2uzVdx.webp", baseCdnUrl + "/c7wq9bemmhy0.webp", baseCdnUrl + "/6LilkjOORVg7.webp", baseCdnUrl + "/KvjgLkAfhVsa.webp", baseCdnUrl + "/zW1XIRW7RRA1.webp", baseCdnUrl + "/vREIAFiw2L2o.webp", baseCdnUrl + "/HDnw0eEdOyQX.webp", baseCdnUrl + "/0E9q1AE0z491.webp", baseCdnUrl + "/aenFrEdIrvjg.webp", baseCdnUrl + "/QXlSFWKr98CF.webp"],
    "soloVocal": [baseCdnUrl + "/x2W60tTT3rJj.webp", baseCdnUrl + "/tgjLyWwSSTiM.webp", baseCdnUrl + "/loChdh1lRGBy.webp", baseCdnUrl + "/T9pELRWjCVWQ.webp", baseCdnUrl + "/ux8lpm1RgUvY.webp"],
    "panitia": [baseCdnUrl + "/b0v5BVLmEJur.webp", baseCdnUrl + "/C4KzkI5t6FC9.webp", baseCdnUrl + "/npKHHj1hyQn2.webp", baseCdnUrl + "/lC5CnqrfCncF.webp", baseCdnUrl + "/Sp02cLuzvkwU.webp", baseCdnUrl + "/22Ipxhd8ndup.webp"],
    "closing": [baseCdnUrl + "/emxIeaTv0Gqm.webp", baseCdnUrl + "/zRDKn5NLSBFF.webp", baseCdnUrl + "/Q9pNm0SCIoom.webp", baseCdnUrl + "/H352Vbr5stYB.webp", baseCdnUrl + "/9eMv7rJtk8wt.webp", baseCdnUrl + "/O22jfZaF0gFo.webp"]
}