import {RestManager} from "./RestManager";

export class Analytics {
    public static onPageOpen(data: object[]): void {
        return void 0;
    }
    public static onButtonClick(data: object[], fn: any): void {
        return void 0;
    }

    public static onPageChange(data: object[]): void {
        return void 0;
    }

    public static onPageClose(data: object[]): void {
        return void 0;
    }

    public static onPageError(data: object[]): void {
        return void 0;
    }
}