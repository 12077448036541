
const baseCdnUrl = "https://querencia-cdn.takiyo.us/image";

export const PictureManager = {
    trailerThumbnail: baseCdnUrl + "/hpbMMMowlrbd.webp",
    recapThumbnail: baseCdnUrl + "/cgRTCKAeFrMY.webp",
    registrations: {
        "QmFza2V0IFB1dHJhUw": baseCdnUrl + "/L9HVdp1ojBoU.webp", // Basketball
        "RnV0c2FsIFB1dHJhUw": baseCdnUrl + "/WYvTwBnepylq.webp", // Futsal
        "TW9kZXJuIERhbmNlUw": baseCdnUrl + "/xWVATaULuifq.webp", // Modern Dance
        "U29sbyBWb2NhbFM": baseCdnUrl + "/5YeiGsk8oClu.webp", // Solo Vocal
        "U3BlZWNoUw": baseCdnUrl + "/MPLB6n7o1nzh.webp", // Speech
        "TWVsdWtpc1M": baseCdnUrl + "/23MHfvrKeYQE.webp", // Melukis
        "RnV0c2FsIFB1dHJhSg": baseCdnUrl + "/WYvTwBnepylq.webp", // Futsal
        "TW9kZXJuIERhbmNlSg": baseCdnUrl + "/xWVATaULuifq.webp", // Modern Dance
        "VmlkZW8gRWRpdGluZ1M": baseCdnUrl + "/fnE3utYr1xvJ.webp", // scene arranging
        "RS1TcG9ydCBNb2JpbGUgTGVnZW5kQQ": baseCdnUrl + "/DVXFZCy06Nc8.webp" // Esports Mobile Legend
    },
    leftRoot: baseCdnUrl + "/XSqMmyd1x6kT.webp",
    rightRoot: baseCdnUrl + "/61yFl6lS6XAW.webp",
    leftTree: baseCdnUrl + "/VEirxQcAMqyU.webp",
    rightTree: baseCdnUrl + "/zLeLLsDKomNH.webp",
    homePicture: {
        lazy: baseCdnUrl + "/odVFclw6wH02.webp",
        normal: baseCdnUrl + "/oNZXAeVVGzX5.png"
    },
    exuberant: baseCdnUrl + "/4jWqyG2VdJZA.webp",
    querencia: baseCdnUrl + "/9xTxkrujE4IX.webp",
    gradient: baseCdnUrl + "/HKi926x3Vu9C.png"
}